import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { actions as handoutsAction } from 'Reducks/Handouts';
import { connect } from 'react-redux';
import config from 'appConfig';
import PropTypes from 'prop-types';
import ChameleonCSSTransition from '@getgo/chameleon-react/dist/ChameleonCSSTransition';
import ContentViewer from 'Components/Modals/ContentViewer';
import { ResponsiveModal, AlertDialog } from '@getgo/chameleon-react';
import EmptyState from 'Components/Card/SharedComponents/EmptyState';
import ProgressBar from 'Components/Card/SharedComponents/ProgressBar';
import { withTranslation } from 'react-i18next';
import Iframe from 'Components/Card/SharedComponents/Iframe';
import TooltipIcon from '@getgo/chameleon-react/dist/TooltipIcon';
import { onHandoutUploadedToSessionTracker,
  onHandoutDeletedFromSessionTracker } from 'Mixpanel/Features/VirtualEventDetails/engagementDetailsTracker';
import noHandoutsRobot from './Assets/noHandoutsRobot.svg';
import HandoutCards from './HandoutCards';
import { handoutFileTypes } from './handoutFileTypes';
import filestackHelper from '../../Utils/filestackHelper';

import styles from './styles.scss';

const MAX_HANDOUT_SIZE = 104857600; // 100MB

class Handouts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadingFilename: '',
      showConfirmationDialog: false,
      showHandoutPreview: false,
      deleteHandoutKey: null,
      selectedHandout: null,
      allHandouts: props.handouts && props.handouts.allHandouts && props.handouts.allHandouts.data
        ? props.handouts.allHandouts.data : [],
      uploadPolicy: props.handouts && props.handouts.uploadPolicy && props.handouts.uploadPolicy.data
        ? props.handouts.uploadPolicy.data : {},
      handoutsConstraints: props.handouts && props.handouts.handoutsConstraints && props.handouts.handoutsConstraints.data
        ? props.handouts.handoutsConstraints.data : {}
    };
  }

  static propTypes = {
    webinarKey: PropTypes.string,
    isPastWebinar: PropTypes.bool,
    onClose: PropTypes.func,
    handouts: PropTypes.object,
    createUploadPolicy: PropTypes.func,
    deleteHandout: PropTypes.func,
    accountKey: PropTypes.string,
    userKey: PropTypes.string,
    isUploading: PropTypes.bool,
    uploadError: PropTypes.bool,
    t: PropTypes.func,
    eventKey: PropTypes.string,
    maxHandoutsCount: PropTypes.number
  }

  displayHandoutsHeader() {
    const { t } = this.props;
    const { isPastWebinar, maxHandoutsCount } = this.props;
    if (isPastWebinar) {
      return null;
    }
    return (
      <div>
        <span id='handouts_sub_header' className={styles.subHeading}>{t('handouts.subHeading', { filesCount: maxHandoutsCount, maxFileSizeInMB: 100 })}</span>
        <a href={config.handoutsLearnMoreUrl} target='_blank' rel="noopener noreferrer" className="link">{t('handouts.learnMore', { maxFileSizeInMB: 100 })}</a>
      </div>
    );
  }

  displayFilestack() {
    const { t } = this.props;
    const { accountKey, userKey, webinarKey } = this.props;
    const { handoutsConstraints } = this.state;
    const allowedMimeTypes = handoutsConstraints && handoutsConstraints.mimeTypes ? handoutsConstraints.mimeTypes : {};
    const filestackAcceptOption = Object.keys(allowedMimeTypes).map((mimeType) => allowedMimeTypes[mimeType]);
    filestackAcceptOption.push('audio/mp3');
    const userWebinarInfo = { accountKey, userKey, webinarKey };
    const fileMaxSize = MAX_HANDOUT_SIZE || Number(handoutsConstraints.allowedFileSizeInBytes);
    const filestackHandoutsOptions = {
      displayMode: 'inline',
      container: '#handouts_filestack',
      accept: filestackAcceptOption,
      customText: {
        'Select Files to Upload': t('handouts.selectFile'),
        'or Drag and Drop, Copy and Paste Files': t('handouts.dragDrop')
      },
      disableTransformer: true,
      exposeOriginalFile: true,
      onFileSelected: (file) => {
        const { mimetype, size } = file;
        if (!filestackAcceptOption.includes(mimetype)) {
          filestackHelper.cancelUpload();
          throw new Error(t('handouts.wrongFileType'));
        } else if (size > fileMaxSize) {
          filestackHelper.cancelUpload();
          throw new Error(t('handouts.wrongFileSize', { limit: fileMaxSize / 1024 / 1024 }));
        }
      },
      onUploadStarted: (files) => {
        this.setState({ uploadingFilename: files[0].filename });
        if (files[0].mimetype === 'audio/mp3') { files[0].mimetype = 'audio/mpeg'; }
        const fileMetaData = [{
          fileName: files[0].filename,
          mimeType: files[0].mimetype,
          bytes: files[0].size
        }];
        const fileObject = files[0].originalFile;
        this.props.createUploadPolicy(this.props.webinarKey, fileMetaData, fileObject, userWebinarInfo);
        onHandoutUploadedToSessionTracker(this.props.eventKey, this.props.webinarKey, fileMetaData, fileObject, userWebinarInfo, 'Uploading Handouts');
      }
    };
    const useOnlyLocalFileSystem = false;
    filestackHelper.pick(filestackHandoutsOptions, useOnlyLocalFileSystem);
  }

  displayHandoutTooltips() {
    const { t } = this.props;
    const { isPastWebinar } = this.props;
    if (this.isMaxHandouts() || isPastWebinar) { return null; }
    const supportedFileTypes = Object.keys(handoutFileTypes).map((fileType) => handoutFileTypes[fileType]).join(', ').toUpperCase();
    return (
      <div className={styles.handoutTooltip}>
        <span id="handouts_tooltip">{t('handouts.fileTypes')}
          <TooltipIcon
            id='handouts_supported_file_types'
            description={t('handouts.tooltip', { supportedFileTypes })}/>
        </span>
      </div>
    );
  }

  displayProgressBar() {
    const { uploadingFilename } = this.state;
    const { uploadError } = this.props;
    return (
      <div className={styles.progressBarContainer}>
        <ProgressBar
          animated={true}
          value={100}
          title={uploadingFilename}
          color={uploadError ? 'danger' : null}
        />
      </div>
    );
  }

  displayHandoutsFilestack() {
    const { isPastWebinar } = this.props;
    if (this.isMaxHandouts() || isPastWebinar === true) { return null; }
    return (
      <div className={styles.handoutsFilestack}>
        <div id="handouts_filestack" className={styles.filestackContainer}>{this.displayFilestack()}</div>
      </div>
    );
  }

  displayHandouts() {
    const { t } = this.props;
    const { webinarKey, isPastWebinar, maxHandoutsCount } = this.props;
    const { allHandouts, handoutsConstraints } = this.state;
    const numOfHandoutsAdded = (allHandouts && allHandouts.length) || 0;
    return (
      <div>
        <div className={styles.handoutsPreviewHeader}>
          <span id='handouts_preview_title' className={styles.handoutsPreviewTitle}>{t('handouts.title')}</span>
          <span id='handouts_number_info' className={styles.handoutsPreviewInfo}>{t('handouts.numberInfo', { numOfHandoutsAdded, maxHandouts: maxHandoutsCount })}</span>
        </div>
        {numOfHandoutsAdded >= 1
          ? this.displayHandoutsPreview(allHandouts, handoutsConstraints, webinarKey, isPastWebinar)
          : this.displayHandoutsEmptyState()}
      </div>
    );
  }

  displayHandoutsEmptyState() {
    const { t } = this.props;
    const data = {
      title: t('handouts.emptyState'),
      image: noHandoutsRobot
    };

    return (
      <div id='handouts_emptystate' className={styles.handoutsEmptyState}>
        <EmptyState data={data} />
      </div>
    );
  }

  renderDeleteConfirmationDialog() {
    const { t } = this.props;
    const { showConfirmationDialog, deleteHandoutKey } = this.state;
    const props = {
      isOpen: showConfirmationDialog,
      alertType: 'warning',
      alertTitle: t('handouts.deleteConfirmationTitle'),
      alertMessage: t('handouts.deleteConfirmationMessage'),
      buttons: [
        { title: t('button.delete'), onClickHandler: this.onDeleteAlert.bind(this, deleteHandoutKey) },
        { title: t('button.cancel'), onClickHandler: this.onCancelAlert }
      ]
    };
    return (<AlertDialog {...props} />);
  }

  onDeleteAlert = (deleteHandoutKey) => {
    this.setState({ showConfirmationDialog: false });
    this.props.deleteHandout(this.props.webinarKey, deleteHandoutKey);
    onHandoutDeletedFromSessionTracker(this.props.eventKey, this.props.webinarKey, deleteHandoutKey, 'Deleting handouts');
  }

  onCancelAlert = () => {
    this.setState({ showConfirmationDialog: false, deleteHandoutKey: null });
  }

  handleDeleteHandout = (handoutKey) => {
    this.setState({ showConfirmationDialog: true, deleteHandoutKey: handoutKey });
  }

  handleShowHandout = (handout) => {
    this.setState({ showHandoutPreview: true, selectedHandout: handout });
  }

  isMaxHandouts() {
    const { allHandouts } = this.state;
    const numOfHandoutsAdded = (allHandouts && allHandouts.length) || 0;
    return numOfHandoutsAdded >= this.props.maxHandoutsCount;
  }

  displayHandoutsPreview(allHandouts, handoutsConstraints, webinarKey, isPastWebinar) {
    return <HandoutCards
      isPastWebinar = {isPastWebinar}
      webinarKey={webinarKey}
      handouts={allHandouts}
      handoutsConstraints={handoutsConstraints}
      deleteHandout={(handoutKey) => { this.handleDeleteHandout(handoutKey); }}
      showHandout={(handout) => { this.handleShowHandout(handout); }}
    />;
  }

  onHandoutPreviewClose = () => {
    this.setState({ showHandoutPreview: false, displaySelectedHandout: null });
  }

  // TODO: Evalute privacy concerns in using officeapp viewer
  createOfficeAppViewUrl(url, objectKey) {
    if (url) {
      const params = url.split('?')[1];
      const handoutUrl = params ? `${config.g2w.handoutFileUrl}/${objectKey}?${params}` : url;
      const encodedHandoutUrl = encodeURIComponent(handoutUrl);
      const officeAppBaseUrl = 'https://view.officeapps.live.com/op/embed.aspx';
      const officeAppUrl = `${officeAppBaseUrl}?src=${encodedHandoutUrl}`;
      return officeAppUrl;
    }
    return null;
  }

  displaySelectedHandout() {
    const { showHandoutPreview, selectedHandout } = this.state;
    if (!selectedHandout) {
      return null;
    }

    const mediaFileType = ['wmv', 'mov', 'mp3', 'mp4'];
    const gViewFileType = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
    const imageFileType = ['png', 'jpg', 'jpeg', 'gif'];
    if (mediaFileType.includes(selectedHandout.filetype) || imageFileType.includes(selectedHandout.filetype)) {
      return (
        <ChameleonCSSTransition enter='fade-in' leave='fade-out' isOpen={showHandoutPreview}>
          <ContentViewer
            previewUrl={selectedHandout._links.file.href}
            title={selectedHandout.filename}
            onClose={this.onHandoutPreviewClose}
            isOnlyAudio={selectedHandout.filetype === 'mp3'}
            isOnlyImage={imageFileType.includes(selectedHandout.filetype)}
            canDownloadMedia={true}
          />
        </ChameleonCSSTransition>
      );
    } if (selectedHandout.filetype === 'pdf' || gViewFileType.includes(selectedHandout.filetype)) {
      let previewUrl;
      if (gViewFileType.includes(selectedHandout.filetype)) {
        previewUrl = this.createOfficeAppViewUrl(selectedHandout._links.file.href, selectedHandout.objectkey);
      } else {
        previewUrl = selectedHandout._links.file.href;
      }
      return (
        <ChameleonCSSTransition enter='fade-in' leave='fade-out' isOpen={showHandoutPreview}>
          <Iframe
            previewUrl={previewUrl}
            title={selectedHandout.filename}
            onClose={this.onHandoutPreviewClose}
          />
        </ChameleonCSSTransition>

      );
    }
    return null;
  }

  render() {
    const { isUploading, isPastWebinar } = this.props;
    const { t } = this.props;
    return (
      <ResponsiveModal onClose={this.props.onClose} isOpen={true} title={isPastWebinar ? '' : t('handouts.title')} titleId='handouts_header'>
        <div className={styles.handoutsContainer}>
          {this.displayHandoutsHeader()}
          {isUploading ? this.displayProgressBar() : this.displayHandoutsFilestack()}
          {this.displayHandoutTooltips()}
          {this.displayHandouts()}
          {this.renderDeleteConfirmationDialog()}
          {this.displaySelectedHandout()}
        </div>
      </ResponsiveModal>
    );
  }
}

export default connect(
  (state) => ({
    handoutsConstraints: state.handouts.handoutsConstraints,
    accountKey: state.user.metadata.accountKey,
    userKey: state.user.metadata.userKey,
    isUploading: state.handouts.isUploading,
    uploadError: state.handouts.uploadError,
    maxHandoutsCount: Number(state.handouts.handoutsConstraints?.data?.filesPerWebinar) || 0
  }),
  (dispatch) => bindActionCreators(Object.assign({},
    handoutsAction), dispatch)
)(withTranslation()(Handouts));
