const environment = process.env.ENVIRONMENT;
const defaults = {
  env: environment,
  wootric: {
    key: 'NPS-29f86f0a'
  },
  bold360: {
    accountId: '744269138093162628',
    websiteId: '2945319327781502018',
    invitationId: '201826779085538911',
    chatId: '739023343391842093'
  },
  broadcastChannels: ['youtube', 'amazon', 'facebook'],
  liveStreamingChannels: ['youtube', 'facebook', 'linkedIn', 'custom'],
  eventLiveStreamingChannels: ['youtube', 'facebook', 'custom'],
  fetchWebinar: {
    intervals: [5000, 10000, 20000, 30000]
  },
  maintenance: {
    onlineUrl: 'https://status.gotomeeting.com/'
  },
  mixpanel: {
    token: 'e6501de8ff865abf0323a500d9cab6b4',
    crossProductToken: '24a5f49d4e1c652bd9d05bcbe6c0faa3'
  },
  payments: {
    termsOfServiceUrl: 'https://www.logmeininc.com/legal/beta-terms-of-service-paymentintegration',
    faqUrl: 'https://link.gotowebinar.com/help-charge-webinars',
    cancellationPolicyUrl: 'https://link.gotowebinar.com/help-charge-cancellation',
    setupFaqUrl: 'https://link.gotowebinar.com/help-charge-setup',
    stripePriceFaq: 'https://stripe.com/pricing#pricing-details',
    stripeMinPriceFaq: 'https://stripe.com/docs/currencies#minimum-and-maximum-charge-amounts'
  },
  audioSettingsLearnMoreUrl: 'https://link.gotowebinar.com/help-set-audio',
  handoutsLearnMoreUrl: 'https://link.gotowebinar.com/help-share-handouts',
  videosLearnMoreUrl: 'https://support.logmeininc.com/supportvideos/gotowebinar/video--quick-tips-and-tricks--gotowebinar-video-sharing-quick-tip',
  recordingsLearnMoreUrl: 'https://link.gotowebinar.com/help-record-webinar',
  sourceTrackingUrl: 'https://link.gotowebinar.com/help-track-source',
  copyWebinarSupportUrl: 'https://support.goto.com/webinar/help/how-do-i-copy-a-previous-webinar-details-to-a-new-one',
  scheduleTitleTipUrl: 'https://link.gotowebinar.com/types',
  scheduleTitleTipUrlForVirtualEvent: 'https://link.gotowebinar.com/help-ve-overview',
  moreAboutStaffUrl: 'https://link.gotowebinar.com/help-user-roles',
  webinarPricingUrl: 'https://www.gotomeeting.com/webinar/pricing',
  webinarSummaryLearnMoreLink: 'https://support.goto.com/webinar/help/ai-summaries',
  interprefy: {
    interprefyLearnMoreLink: 'https://link.gotowebinar.com/interprefy',
    intreprefyTokenUrl: 'https://share.hsforms.com/1phsg22j-R06uyv8oLrjGVw3wowi'
  },
  youTube: {
    googleApiBaseUrl: 'https://www.googleapis.com',
    googleApiKey: 'AIzaSyA4wqQXhqcm1xSxc1xDku1THcQ4e6W9Ugw',
    thumbnailBaseUrl: 'https://img.youtube.com',
    embedBaseUrl: 'https://www.youtube-nocookie.com'
  },
  support: {
    supportUrl: 'https://link.gotowebinar.com/plogin',
    communityUrl: 'https://community.logmein.com/t5/GoToWebinar-Discussions/bd-p/GoToWebinar-Discussion',
    facebookUrl: 'https://www.facebook.com/GoToWebinar',
    twitterUrl: 'https://twitter.com/gotowebinar',
    g2s: 'https://link.gotowebinar.com/help-gotostage',
    g2w: 'https://support.goto.com/webinar',
    training: 'http://link.gotowebinar.com/PrdGtwEngInPrdMenu',
    eventProductionServices: 'https://www.goto.com/webinar/virtual-event-services',
    learnMore: {
      recordedEvent: 'https://support.goto.com/webinar/help/simulated-live-beta-webinars-g2w090140',
      webcastEvent: 'https://link.gotowebinar.com/help-webcast',
      livestreamConfiguration: 'https://support.goto.com/webinar/help/how-do-i-live-stream-my-webinar-to-other-platforms-like-youtube-and-facebook',
      generateTranscript: 'https://support.goto.com/webinar/help/manage-recordings',
      bulkRegistration: 'https://link.gotowebinar.com/bulk_registration'
    },
    en_US: {
      liveTrainingUrl: 'https://link.gotowebinar.com/live-training-iph',
      onDemandUrl: 'https://link.gotowebinar.com/recorded-training-iph'
    },
    de_DE: {
      liveTrainingUrl: 'https://link.gotowebinar.com/live-training-iph',
      onDemandUrl: 'https://link.gotowebinar.com/recorded-training-iph'
    },
    es_ES: {
      liveTrainingUrl: 'https://link.gotowebinar.com/live-training-iph',
      onDemandUrl: 'https://link.gotowebinar.com/recorded-training-iph'
    },
    fr_FR: {
      liveTrainingUrl: 'https://link.gotowebinar.com/live-training-iph',
      onDemandUrl: 'https://link.gotowebinar.com/recorded-training-iph'
    },
    it_IT: {
      liveTrainingUrl: 'https://link.gotowebinar.com/live-training-iph',
      onDemandUrl: 'https://link.gotowebinar.com/recorded-training-iph'
    }
  },
  feedbackUrl: 'https://logmein.co1.qualtrics.com/SE/',
  feedbackSurveyId: 'SV_9NX9anBlcGaPECW',

  // eslint-disable-next-line no-undef
  appName: __APP_NAME__,
  // eslint-disable-next-line no-undef
  appVersion: __APP_VERSION__
};

const ed = Object.assign({}, defaults, {
  appEnv: 'ed',
  g2w: {
    url: 'https://globaled1.g2w.test.expertcity.com',
    registrationUrl: 'https://attendeeed1.g2w.test.expertcity.com',
    simuliveUrl: 'https://apped1.g2w.test.expertcity.com',
    events: 'https://virtualevents.services-ed.goto.com/eventsservice',
    question: 'https://questionnaire.services-ed.goto.com/gotoquestionnaireservice/api/v1',
    agenda: 'https://evented.svc.g2w.test.expertcity.com',
    handoutFileUrl: 'https://handouts-ed.s3.amazonaws.com',
    summaryUrl: 'https://apied-int-assetorchestrationservice.services-ed.goto.com/assetorchestrationservice/api/v1',
    generateSummaryUrl: 'https://apied-int-summaryservice.services-ed.goto.com/summaryservice/api/v1'
  },
  liveStream: {
    url: 'https://features-api.services-ed.goto.com',
    linkedInClientId: '78lp8r5zr81rgu'
  },
  g2s: {
    url: 'https://apied1.gotostage.test.expertcity.com',
    web: 'https://ed.gotostage.test.expertcity.com'
  },
  logging: {
    url: 'https://logginged1.getgo.test.expertcity.com'
  },
  oauth: {
    url: 'https://authenticationed1.col.test.expertcity.com',
    clientId: '6d973c1e-45f2-4f7e-bd80-8bad2caeb8e3',
    goToOpenerClientId: 'dc052542-ead1-49ad-a357-277178ee5147'
  },
  g2wReporting: {
    url: 'https://clouded1.g2w.test.expertcity.com'
  },
  g2wVirtualEventReporting: {
    url: 'https://reporting.services-ed.goto.com/reporting'
  },
  accountService: {
    url: 'https://iamed1.serversdev.getgo.com',
    adminUrl: 'https://admined1.lmiinc.test.expertcity.com'
  },
  brandingService: {
    url: 'https://branding.services-ed.goto.com'
  },
  socialGraphService: {
    url: 'https://socialgraph.serversdev.getgo.com'
  },
  assetsService: {
    url: 'https://apied.recordingassets.lmiinc.test.expertcity.com'
  },
  featureService: {
    url: 'https://apied-featureservices.devplatform-dev.expertcity.com'
  },
  messageBus: {
    url: 'https://apied-int-eventsgateway.services-ed.goto.com/eventsgateway/events'
  },
  avatar: {
    url: 'https://avatarsed1.serversdev.getgo.com'
  },
  notificationservice: {
    webPushPublicKey: 'BKECtyDnob90OQWXb4Pjk8a268G2ug0Ztc-uKADqVW6qr55yFz3XsLNsLuq3OkVMs3DuBWHGX8Ru1YL4Q0Kv8ak'
  },
  meetingService: {
    url: 'https://apiglobaled1.g2m.test.expertcity.com'
  },
  urls: {
    admin: 'https://admined1.lmiinc.test.expertcity.com',
    support: 'https://support.logmeininc.com/gotowebinar',
    g2a: 'https://i1app.test.g2ad.expertcity.com',
    ov: 'https://globaled1.openvoice.com',
    g2m: 'https://globaled1.g2m.test.expertcity.com',
    gotoApp: 'https://app.dev.gtc.goto.com/meetings',
    g2t: 'https://globaled1.g2t.test.expertcity.com',
    analytics: 'https://analyticsed.g2w.test.expertcity.com',
    myAccount: 'https://myaccounted.serversdev.getgo.com/',
    register: 'https://attendeeed1.g2w.test.expertcity.com/register',
    privacy: 'https://www.logmeininc.com/legal/privacy',
    terms: 'https://www.logmeininc.com/legal/terms-and-conditions',
    goto: 'https://www.goto.com/'
  },
  gotoUrls: {
    antiSpamPolicy: 'https://www.goto.com/company/legal/anti-spam-policy',
    terms: 'https://www.goto.com/company/legal/terms-and-conditions',
    support: 'http://support.goto.com/'
  },
  sentry: {
    url: 'https://3c2500766bb04608bace0caf0be007ef@sentry.io/1532103'
  },
  filestack: {
    key: 'AVy9tFcWmQyuYfheWhBcPz',
    // JSON Policy Structure for reference {"call":["convert","pick","read","write","store","remove"]}
    // Policy Valid upto 2030-12-31
    policy: 'eyJjYWxsIjpbInBpY2siLCJyZWFkIiwid3JpdGUiLCJzdG9yZSIsImNvbnZlcnQiLCJyZW1vdmUiXSwiZXhwaXJ5IjoxOTI0ODg1ODAwfQ==',
    signature: '378a07e831d1ab493f8e733ceca9d141be4b21e11ad339d23aa6fb3f4ec4ec6d',
    g2s: {
      key: 'ARHjiGSx9TzaILDjAQe3ez',
      policy: 'eyJleHBpcnkiOjE3NjcxMTk0MDAsImNhbGwiOlsicGljayIsInJlYWQiLCJ3cml0ZSIsInN0b3JlIiwicmVtb3ZlIl19',
      signature: 'f2b45ea7bde4426c246f2ad23f0723d20219dce187cfc98c7a2b28e41b860559'
    },
    storage: {
      s3: {
        path: 'ed/webinardotcom/filepicker',
        baseUrl: 'https://s3.amazonaws.com'
      }
    }
  },
  ipd: {
    trial: 'https://www.gotomeeting.com/webinar/buy/upgrade',
    upgrade: {
      offlineUrl: 'https://lp.logmeininc.com/GoToWebinar-Upgrade-Get-in-Touch.html',
      onlineUrl: 'https://billinged.serversdev.getgo.com/subscriptions/configure?productFamily=G2W&productKey=G2W_TEAM'
    }
  },
  engagementService: {
    url: 'https://g2w-ee.dev.mltree.net'
  },
  registration: {
    url: 'https://attendeeed1.g2w.test.expertcity.com',
    virtualEventUrl: 'https://evented.svc.g2w.test.expertcity.com'
  },
  qAndAService: { url: 'https://apied-int-qanda.services-ed.goto.com/qanda' },
  logger: {
    url: 'https://logginged1.getgo.test.expertcity.com:443'
  },
  appStateService: {
    productId: 'e2e_dashboard',
    accessToken: '103e2fee-e510-4197-833f-c4fb91434dbe'
  },
  launchDarkly: {
    clientId: '5be2128248b4d42ff691b7ef'
  },
  paymentService: {
    url: 'https://apied-featureservices.devplatform-dev.expertcity.com/paymentsservice'
  },
  couponService: {
    url: 'https://apied-int-couponservice.services-ed.goto.com/couponservice'
  },
  settingsService: {
    url: 'https://apied-int-gotosettingsservice.services-ed.goto.com/gotosettingsservice/v1/settings'
  },
  userSettingService: {
    url: 'https://apied-int-usersettingservice.services-ed.goto.com/usersettingservice'
  },
  channelService: {
    url: 'https://apied-int-channelservice.services-ed.goto.com/channelservice'
  },
  contentService: {
    url: 'https://apied-int-contentservice.services-ed.goto.com/contentservice'
  },
  transcriptEditingService: {
    url: 'https://apied-int-transcripteditingservice.services-ed.goto.com/transcripteditingservice'
  },
  videoEditingService: {
    url: 'https://apied-int-videoediting.services-ed.goto.com'
  },
  applicationStateService: {
    url: 'https://apied-int-applicationstateservice.services-ed.goto.com'
  }
});

const rc = Object.assign({}, defaults, {
  appEnv: 'rc',
  logging: {
    url: 'https://loggingrc1.getgo.test.expertcity.com'
  },
  g2w: {
    url: 'https://globalrc1.g2w.test.expertcity.com',
    registrationUrl: 'https://attendeerc1.g2w.test.expertcity.com',
    simuliveUrl: 'https://apprc1.g2w.test.expertcity.com',
    events: 'https://virtualevents.services-rc.goto.com/eventsservice',
    question: 'https://questionnaire.services-rc.goto.com/gotoquestionnaireservice/api/v1',
    agenda: 'https://eventrc.svc.g2w.test.expertcity.com',
    handoutFileUrl: 'https://handouts-rc.s3.amazonaws.com',
    summaryUrl: 'https://apirc-int-assetorchestrationservice.services-rc.goto.com/assetorchestrationservice/api/v1',
    generateSummaryUrl: 'https://apirc-int-summaryservice.services-rc.goto.com/summaryservice/api/v1'
  },
  liveStream: {
    url: 'https://features-api.services-rc.goto.com',
    linkedInClientId: '78lp8r5zr81rgu'
  },
  g2s: {
    url: 'https://apirc1.gotostage.test.expertcity.com',
    web: 'https://rc.gotostage.test.expertcity.com'
  },
  g2wReporting: {
    url: 'https://cloudrc1.g2w.test.expertcity.com'
  },
  g2wVirtualEventReporting: {
    url: 'https://reporting.services-rc.goto.com/reporting'
  },
  oauth: {
    url: 'https://loginrc2.lmiinc.test.expertcity.com/oauth',
    clientId: '1006b182-4bc4-4b9f-bf4e-3fa5d7900ebc',
    goToOpenerClientId: '5bfbed10-8bf2-4b23-89d3-884a6b7426f7'
  },
  accountService: {
    url: 'https://iamrc1.serversdev.getgo.com',
    adminUrl: 'https://adminrc1.lmiinc.test.expertcity.com'
  },
  brandingService: {
    url: 'https://branding.services-rc.goto.com'
  },
  socialGraphService: {
    url: 'https://socialgraphrc.serversdev.getgo.com'
  },
  assetsService: {
    url: 'https://apirc.recordingassets.lmiinc.test.expertcity.com'
  },
  featureService: {
    url: 'https://apirc-featureservices.devplatform-dev.expertcity.com'
  },
  messageBus: {
    url: 'https://apirc-int-eventsgateway.services-rc.goto.com/eventsgateway/events'
  },
  avatar: {
    url: 'https://avatarsrc1.serversdev.getgo.com'
  },
  notificationservice: {
    webPushPublicKey: 'BKECtyDnob90OQWXb4Pjk8a268G2ug0Ztc-uKADqVW6qr55yFz3XsLNsLuq3OkVMs3DuBWHGX8Ru1YL4Q0Kv8ak'
  },
  meetingService: {
    url: 'https://apiglobalrc1.g2m.test.expertcity.com'
  },
  urls: {
    admin: 'https://adminrc1.lmiinc.test.expertcity.com',
    support: 'https://support.logmeininc.com/gotowebinar',
    g2t: 'https://globalrc1.g2t.test.expertcity.com',
    g2a: 'https://pilotapp.test.g2ad.expertcity.com',
    analytics: 'https://analyticsrc.g2w.test.expertcity.com',
    g2m: 'https://globalrc1.g2m.test.expertcity.com',
    gotoApp: 'https://app.rc.gtc.goto.com/meetings',
    myAccount: 'https://myaccountrc.serversdev.getgo.com/',
    register: 'https://attendeerc1.g2w.test.expertcity.com/register',
    ov: 'https://globalrc1.openvoice.com',
    privacy: 'https://www.logmeininc.com/legal/privacy',
    terms: 'https://www.logmeininc.com/legal/terms-and-conditions',
    goto: 'https://www.goto.com/'
  },
  gotoUrls: {
    antiSpamPolicy: 'https://www.goto.com/company/legal/anti-spam-policy',
    terms: 'https://www.goto.com/company/legal/terms-and-conditions',
    support: 'http://support.goto.com/'
  },
  sentry: {
    url: 'https://678c927710cc4bba886d54fca17bfe46@sentry.io/1532104'
  },
  filestack: {
    key: 'AVy9tFcWmQyuYfheWhBcPz',
    // JSON Policy Structure for reference {"call":["convert","pick","read","write","store","remove"]}
    // Policy Valid upto 2030-12-31
    policy: 'eyJjYWxsIjpbInBpY2siLCJyZWFkIiwid3JpdGUiLCJzdG9yZSIsImNvbnZlcnQiLCJyZW1vdmUiXSwiZXhwaXJ5IjoxOTI0ODg1ODAwfQ==',
    signature: '378a07e831d1ab493f8e733ceca9d141be4b21e11ad339d23aa6fb3f4ec4ec6d',
    g2s: {
      key: 'ARHjiGSx9TzaILDjAQe3ez',
      policy: 'eyJleHBpcnkiOjE3NjcxMTk0MDAsImNhbGwiOlsicGljayIsInJlYWQiLCJ3cml0ZSIsInN0b3JlIiwicmVtb3ZlIl19',
      signature: 'f2b45ea7bde4426c246f2ad23f0723d20219dce187cfc98c7a2b28e41b860559'
    },
    storage: {
      s3: {
        path: 'rc/webinardotcom/filepicker',
        baseUrl: 'https://s3.amazonaws.com'
      }
    }
  },
  ipd: {
    trial: 'https://www.gotomeeting.com/webinar/buy/upgrade',
    upgrade: {
      offlineUrl: 'https://lp.logmeininc.com/GoToWebinar-Upgrade-Get-in-Touch.html',
      onlineUrl: 'https://billingrc.serversdev.getgo.com/subscriptions/configure?productFamily=G2W&productKey=G2W_TEAM'
    }
  },
  engagementService: {
    url: 'https://g2w-ee.rc.mltree.net'
  },
  registration: {
    url: 'https://attendeerc1.g2w.test.expertcity.com',
    virtualEventUrl: 'https://eventrc.svc.g2w.test.expertcity.com'
  },
  qAndAService: { url: 'https://apirc-int-qanda.services-rc.goto.com/qanda' },
  logger: {
    url: 'https://loggingrc1.col.test.expertcity.com:443'
  },
  appStateService: {
    productId: 'e2e_dashboard',
    accessToken: '8c0dc28a-b666-4184-99fc-f0e80a015280'
  },
  launchDarkly: {
    clientId: '5be5e3d9b3ea0a302d748678'
  },
  paymentService: {
    url: 'https://apirc-featureservices.devplatform-dev.expertcity.com/paymentsservice'
  },
  couponService: {
    url: 'https://apirc-int-couponservice.services-rc.goto.com/couponservice'
  },
  settingsService: {
    url: 'https://apirc-int-gotosettingsservice.services-rc.goto.com/gotosettingsservice/v1/settings'
  },
  userSettingService: {
    url: 'https://apirc-int-usersettingservice.services-rc.goto.com/usersettingservice'
  },
  channelService: {
    url: 'https://apirc-int-channelservice.services-rc.goto.com/channelservice'
  },
  contentService: {
    url: 'https://apirc-int-contentservice.services-rc.goto.com/contentservice'
  },
  transcriptEditingService: {
    url: 'https://apirc-int-transcripteditingservice.services-rc.goto.com/transcripteditingservice'
  },
  videoEditingService: {
    url: 'https://apirc-int-videoediting.services-rc.goto.com'
  },
  applicationStateService: {
    url: 'https://apirc-int-applicationstateservice.services-rc.goto.com'
  }
});

const stage = Object.assign({}, defaults, {
  appEnv: 'stage',
  logging: {
    url: 'https://loggingstage.getgo.com'
  },
  g2w: {
    url: 'https://globalstage.gotowebinar.com',
    registrationUrl: 'https://attendeestage.gotowebinar.com',
    simuliveUrl: 'https://appstage.gotowebinar.com',
    events: 'https://virtualevents.services-stage.goto.com/eventsservice',
    question: 'https://questionnaire.services-stage.goto.com/gotoquestionnaireservice/api/v1',
    agenda: 'https://eventstage.gotowebinar.com',
    handoutFileUrl: 'https://handouts-stage.s3.amazonaws.com',
    summaryUrl: 'https://apistage-int-assetorchestrationservice.services-stage.goto.com/assetorchestrationservice/api/v1',
    generateSummaryUrl: 'https://apistage-int-summaryservice.services-stage.goto.com/summaryservice/api/v1'
  },
  liveStream: {
    url: 'https://features-api.services-stage.goto.com',
    linkedInClientId: '77y0y7gxi2h6na'
  },
  g2s: {
    url: 'https://apistage.gotostage.com',
    web: 'https://stage.gotostage.com'
  },
  g2wReporting: {
    url: 'https://cloudstage.gotowebinar.com'
  },
  g2wVirtualEventReporting: {
    url: 'https://reporting.services-stage.goto.com/reporting'
  },
  oauth: {
    url: 'https://authenticationstage.logmeininc.com/oauth',
    clientId: 'd67888a3-0356-4f23-8da2-63f7a583b158',
    goToOpenerClientId: 'ee4065d3-c28f-436e-a3e6-51d3b7b692f2'
  },
  accountService: {
    url: 'https://iamstage.servers.getgo.com',
    adminUrl: 'https://adminstage.logmeininc.com'
  },
  brandingService: {
    url: 'https://branding.services-stage.goto.com'
  },
  socialGraphService: {
    url: 'https://socialgraph.servers.getgo.com'
  },
  assetsService: {
    url: 'https://apistage.recordingassets.logmeininc.com'
  },
  featureService: {
    url: 'https://apistage.services.gotomeeting.com'
  },
  messageBus: {
    url: 'https://apistage-int-eventsgateway.services-stage.goto.com/eventsgateway/events'
  },
  avatar: {
    url: 'https://avatarsstage.servers.getgo.com'
  },
  notificationservice: {
    webPushPublicKey: 'BNK37D-zxuFRNPjzjsf_c9hSjm0l08W9ZcnrVcphGV4wmvrUDuDvQn8tdqgDP3RsclmU_1L0OTI3L2Xu9VbsfP4='
  },
  meetingService: {
    url: 'https://apiglobalstage.gotomeeting.com'
  },
  urls: {
    admin: 'https://adminstage.logmeininc.com',
    support: 'https://support.logmeininc.com/gotowebinar',
    ov: 'https://globalstage.openvoice.com',
    analytics: 'https://analyticsstage.gotowebinar.com',
    g2m: 'https://globalstage.gotomeeting.com',
    gotoApp: 'https://app.staging.gtc.goto.com/meetings',
    g2t: 'https://globalstage.gototraining.com',
    g2a: 'https://app.gotoassist.com',
    myAccount: 'https://myaccountstage.logmeininc.com/',
    register: 'https://attendeestage.gotowebinar.com/register',
    privacy: 'https://www.logmeininc.com/legal/privacy',
    terms: 'https://www.logmeininc.com/legal/terms-and-conditions',
    goto: 'https://www.goto.com/'
  },
  gotoUrls: {
    antiSpamPolicy: 'https://www.goto.com/company/legal/anti-spam-policy',
    terms: 'https://www.goto.com/company/legal/terms-and-conditions',
    support: 'http://support.goto.com/'
  },
  sentry: {
    url: 'https://fb31433f75854371ab40bb847ecbec6f@sentry.io/1532105'
  },
  filestack: {
    key: 'AVy9tFcWmQyuYfheWhBcPz',
    // JSON Policy Structure for reference {"call":["convert","pick","read","write","store","remove"]}
    // Policy Valid upto 2030-12-31
    policy: 'eyJjYWxsIjpbInBpY2siLCJyZWFkIiwid3JpdGUiLCJzdG9yZSIsImNvbnZlcnQiLCJyZW1vdmUiXSwiZXhwaXJ5IjoxOTI0ODg1ODAwfQ==',
    signature: '378a07e831d1ab493f8e733ceca9d141be4b21e11ad339d23aa6fb3f4ec4ec6d',
    g2s: {
      key: 'AL08Jt8HPRbSDqrT7hg3yz',
      policy: 'eyJleHBpcnkiOjE3NjcxMTk0MDAsImNhbGwiOlsicGljayIsInJlYWQiLCJ3cml0ZSIsInN0b3JlIiwicmVtb3ZlIl19',
      signature: '033d427931e2cb4a58eb66e416f4dfe10eb64cbd4964f7db1672280ea570cfe4'
    },
    storage: {
      s3: {
        path: 'stage/webinardotcom/filepicker',
        baseUrl: 'https://s3.amazonaws.com'
      }
    }
  },
  ipd: {
    trial: 'https://www.gotomeeting.com/webinar/buy/upgrade',
    upgrade: {
      offlineUrl: 'https://lp.logmeininc.com/GoToWebinar-Upgrade-Get-in-Touch.html',
      onlineUrl: 'https://billingstage.servers.getgo.com/subscriptions/configure?productFamily=G2W&productKey=G2W_TEAM'
    }
  },
  engagementService: {
    url: 'https://g2w-ee.stage.mltree.net'
  },
  registration: {
    url: 'https://attendeestage.gotowebinar.com',
    virtualEventUrl: 'https://eventstage.gotowebinar.com'
  },
  qAndAService: { url: 'https://apistage-int-qanda.services-stage.goto.com/qanda' },
  logger: {
    url: 'https://loggingstage.getgo.com:443'
  },
  appStateService: {
    productId: 'e2e_dashboard',
    accessToken: 'de1c0c57-2f72-48bb-92a7-798e206ac9df'
  },
  launchDarkly: {
    clientId: '5be5e3e40b163a3033e89d64'
  },
  paymentService: {
    url: 'https://apistage.services.gotomeeting.com/paymentsservice'
  },
  couponService: {
    url: 'https://apistage-int-couponservice.services-stage.goto.com/couponservice'
  },
  stripe: {
    baseUrl: 'https://connect.stripe.com',
    clientId: 'ca_EgcWqiA8ZFfUgmP2ShTiT9qek46OEfYx'
  },
  settingsService: {
    url: 'https://apistage-int-gotosettingsservice.services-stage.goto.com/gotosettingsservice/v1/settings'
  },
  userSettingService: {
    url: 'https://apistage-int-usersettingservice.services-stage.goto.com/usersettingservice'
  },
  channelService: {
    url: 'https://apistage-int-channelservice.services-stage.goto.com/channelservice'
  },
  contentService: {
    url: 'https://apistage-int-contentservice.services-stage.goto.com/contentservice'
  },
  transcriptEditingService: {
    url: 'https://apistage-int-transcripteditingservice.services-stage.goto.com/transcripteditingservice'
  },
  videoEditingService: {
    url: 'https://apistage-int-videoediting.services-stage.goto.com'
  },
  applicationStateService: {
    url: 'https://apistage-int-applicationstateservice.services-stage.goto.com'
  }

});

const live = Object.assign({}, defaults, {
  env: 'production',
  appEnv: 'live',
  bold360: {
    accountId: '744269138093162628',
    websiteId: '2945319327781502018',
    invitationId: '201826779085538911',
    chatId: '738998678928212396'
  },
  logging: {
    url: 'https://logging.getgo.com'
  },
  g2w: {
    url: 'https://global.gotowebinar.com',
    registrationUrl: 'https://attendee.gotowebinar.com',
    simuliveUrl: 'https://app.gotowebinar.com',
    events: 'https://virtualevents.services.goto.com/eventsservice',
    question: 'https://questionnaire.services.goto.com/gotoquestionnaireservice/api/v1',
    agenda: 'https://event.gotowebinar.com',
    handoutFileUrl: 'https://handouts-live.s3.amazonaws.com',
    summaryUrl: 'https://api-assetorchestrationservice.services.goto.com/assetorchestrationservice/api/v1',
    generateSummaryUrl: 'https://api-summaryservice.services.goto.com/summaryservice/api/v1'
  },
  liveStream: {
    url: 'https://features-api.services.goto.com',
    linkedInClientId: '77u2acs5ldo08z'
  },
  g2s: {
    url: 'https://api.gotostage.com',
    web: 'https://gotostage.com'
  },
  g2wReporting: {
    url: 'https://cloud.gotowebinar.com'
  },
  g2wVirtualEventReporting: {
    url: 'https://reporting.services.goto.com/reporting'
  },
  mixpanel: {
    token: 'ddfdcac48a1870e9ea86e52c81476af8',
    crossProductToken: '393c9d23daed13b2a940771b2d675355'
  },
  oauth: {
    url: 'https://authentication.logmeininc.com/oauth',
    clientId: 'dc09563b-c2f3-4156-8d5c-4747b8bdf2ad',
    goToOpenerClientId: '7dc99d97-1695-428e-bb92-f8a8bd313c4c'
  },
  accountService: {
    url: 'https://iam.servers.getgo.com',
    adminUrl: 'https://admin.logmeininc.com'
  },
  brandingService: {
    url: 'https://branding.services.goto.com'
  },
  socialGraphService: {
    url: 'https://socialgraph.servers.getgo.com'
  },
  assetsService: {
    url: 'https://api.recordingassets.logmeininc.com'
  },
  featureService: {
    url: 'https://api.services.gotomeeting.com'
  },
  messageBus: {
    url: 'https://api-eventsgateway.services.goto.com/eventsgateway/events'
  },
  avatar: {
    url: 'https://avatars.servers.getgo.com'
  },
  notificationservice: {
    webPushPublicKey: 'BPK-72_V6-kgxWN2xsP8uwS0kSOut6wKPR5rLIAzmqXoYLkTG8K15qqmGYGg4jia-S9FF5n5z0SzrYotKg52Cb8='
  },
  meetingService: {
    url: 'https://apiglobal.gotomeeting.com'
  },
  urls: {
    aboutUs: 'https://www.logmeininc.com/about-us',
    admin: 'https://admin.logmeininc.com',
    support: 'https://support.logmeininc.com/gotowebinar',
    analytics: 'https://analytics.gotowebinar.com',
    ov: 'https://global.openvoice.com',
    g2m: 'https://global.gotomeeting.com',
    gotoApp: 'https://app.goto.com/meetings',
    g2t: 'https://global.gototraining.com',
    g2a: 'https://app.gotoassist.com',
    myAccount: 'https://myaccount.logmeininc.com/',
    register: 'https://attendee.gotowebinar.com/register',
    privacy: 'https://www.logmeininc.com/legal/privacy',
    terms: 'https://www.logmeininc.com/legal/terms-and-conditions',
    goto: 'https://www.goto.com/'
  },
  gotoUrls: {
    antiSpamPolicy: 'https://www.goto.com/company/legal/anti-spam-policy',
    terms: 'https://www.goto.com/company/legal/terms-and-conditions',
    support: 'http://support.goto.com/'
  },
  sentry: {
    url: 'https://bb421d6349714210ba69191524c1a638@sentry.io/1532106'
  },
  filestack: {
    key: 'ASkJ1Mpr6Rz6VLgugVbKlz',
    // JSON Policy Structure for reference {"call":["convert","pick","read","write","store","remove"]}
    // Policy Valid upto 2030-12-31
    policy: 'eyJleHBpcnkiOjE5MjQ4ODU4MDAsImNhbGwiOlsicGljayIsInJlYWQiLCJ3cml0ZSIsInN0b3JlIiwiY29udmVydCIsInJlbW92ZSJdfQ==',
    signature: 'ae9538e3b36ece8d0a065ade9a408d8b42ec60e4b481ad539e4e77d56687526f',
    g2s: {
      key: 'AL08Jt8HPRbSDqrT7hg3yz',
      policy: 'eyJleHBpcnkiOjE3NjcxMTk0MDAsImNhbGwiOlsicGljayIsInJlYWQiLCJ3cml0ZSIsInN0b3JlIiwicmVtb3ZlIl19',
      signature: '033d427931e2cb4a58eb66e416f4dfe10eb64cbd4964f7db1672280ea570cfe4'
    },
    storage: {
      s3: {
        path: 'live/webinardotcom/filepicker',
        baseUrl: 'https://s3.amazonaws.com'
      }
    }
  },
  ipd: {
    trial: 'https://www.gotomeeting.com/webinar/buy/upgrade',
    upgrade: {
      offlineUrl: 'https://lp.logmeininc.com/GoToWebinar-Upgrade-Get-in-Touch.html',
      onlineUrl: 'https://billing.logmeininc.com/subscriptions/configure?productFamily=G2W&productKey=G2W_TEAM'
    }
  },
  engagementService: {
    url: 'https://g2w-ee.mltree.net'
  },
  registration: {
    url: 'https://attendee.gotowebinar.com',
    virtualEventUrl: 'https://event.gotowebinar.com'
  },
  qAndAService: {
    url: 'https://api-qanda.services.goto.com/qanda'
  },
  launchDarkly: {
    clientId: '5be2128248b4d42ff691b7f0'
  },
  logger: {
    url: 'https://logging.getgo.com:443'
  },
  appStateService: {
    productId: 'e2e_dashboard',
    accessToken: '2ba854e2-a719-4faf-89b9-d1cdaf95b3a1'
  },
  paymentService: {
    url: 'https://api.services.gotomeeting.com/paymentsservice'
  },
  couponService: {
    url: 'https://api-couponservice.services.goto.com/couponservice'
  },
  settingsService: {
    url: 'https://api-gotosettingsservice.services.goto.com/gotosettingsservice/v1/settings'
  },
  userSettingService: {
    url: 'https://api-usersettingservice.services.goto.com/usersettingservice'
  },
  channelService: {
    url: 'https://api-channelservice.services.goto.com/channelservice'
  },
  contentService: {
    url: 'https://api-contentservice.services.goto.com/contentservice'
  },
  transcriptEditingService: {
    url: 'https://api-transcripteditingservice.services.goto.com/transcripteditingservice'
  },
  videoEditingService: {
    url: 'https://api-videoediting.services.goto.com'
  },
  applicationStateService: {
    url: 'https://api-applicationstateservice.services.goto.com'
  }
});

export function getCurrentConfig(env) {
  switch (env) {
    case 'dev':
    case 'ed':
      return ed;
    case 'rc':
      return rc;
    case 'stage':
      return stage;
    case 'live':
      return live;
    default: {
      return ed;
    }
  }
}

export default getCurrentConfig(environment);
