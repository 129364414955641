import { createSelector } from 'reselect';
import { OCCURRENCE_TYPE } from '../../Components/Card/ScheduleWebinarV2/WebinarOccurrence/constants';
import { selectTimeFormat } from '../ScheduleWebinar/selectors';
import { WEBINAR_TYPES } from '../../Components/Card/ScheduleWebinarV2/WebinarType';
import { webinarDayDateFormat } from '../../Utils/formats';
import { extractNextUpcomingSessionDateTimeForWebinar,
  formatDateAndTimeForOnDemand,
  formatDateAndTimeForTimeZone,
  getDateTimeFormattedString } from '../../Components/Card/Events/webinarDateHelper';
// TODO:REFACTOR we need to store the current webinar data using global state.
const getWebinar = (_, { webinar }) => webinar;
export const selectParsedWebinarDateTime = createSelector(
  [selectTimeFormat, getWebinar],
  (timeFormat, webinar) => {
    const timesRaw = extractNextUpcomingSessionDateTimeForWebinar(webinar);
    const { day, time } = formatDateAndTimeForTimeZone(timesRaw, webinar.timeZone, undefined, timeFormat);
    let expiration = null;
    if (webinar.recurrencePeriod.toLowerCase() === OCCURRENCE_TYPE.ONDEMAND) {
      expiration = getDateTimeFormattedString(timesRaw.endTime, webinarDayDateFormat);
    }
    return { day, time, expiration };
  }
);
export const selectExperienceTypes = createSelector(
  [(state) => state.webinars.supportedWebinarTypes],
  (webinarTypes) => {
    let experienceTypes = '';
    experienceTypes = webinarTypes.isSimuliveEnabled ? experienceTypes += `${WEBINAR_TYPES.SIMULIVE},` : experienceTypes;
    experienceTypes = webinarTypes.isAVBroadcastEnabled ? experienceTypes += `${WEBINAR_TYPES.WEBCAST},` : experienceTypes;
    experienceTypes = webinarTypes.isStandardBroadcastProvisioned ? experienceTypes += `${WEBINAR_TYPES.CLASSIC}` : experienceTypes;
    return experienceTypes;
  }
);
export const selectIsWebinarsFetching = createSelector(
  [(state) => state._entities.webinars.isFetching],
  (isFetching) => isFetching
);
export const selectWebinars = createSelector(
  [
    (state) => state._entities.webinars.byId,
    (state) => state._entities.webinars.ids,
    (state) => state.ui.eventTabIndex,
    (state) => state.ui.showAllEvents,
    (state) => state.ui.isEventCompactView
  ],
  (webinarsById, ids, eventTabIndex, isEventCompactView, showAllEvents) => {
    const webinars = ids.map((id) => webinarsById[id]);
    const isHidden = (eventTabIndex === 0 && !isEventCompactView && !showAllEvents);
    if (isHidden) {
      return webinars.slice(0, 2);
    }
    return webinars;
  }
);
export const selectOnDemandDateTime = createSelector(
  [selectTimeFormat, getWebinar],
  (timeFormat, webinar) => {
    const timesRaw = extractNextUpcomingSessionDateTimeForWebinar(webinar);
    const { startDateTime, endDateTime, onDemandTimeZone } = formatDateAndTimeForOnDemand(timesRaw, webinar.timeZone, undefined, timeFormat);
    return { startDateTime, endDateTime, onDemandTimeZone };
  }
);

export const selectDeleteWebinarFilters = createSelector(
  [
    (state) => state._entities.paginations.byId.webinars,
    (state) => state._entities.dateFilters.byId.webinars
  ],
  (pagination, dateRange) => ({
    pagination,
    dateRange
  })
);
