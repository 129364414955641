import moment from 'moment';

const getNextStartTime = (webinar) => {
  const { times } = webinar;
  const now = Date.now();

  let nextTimes = times[times.length - 1];

  for (let i = 0; i < times.length; i++) {
    const time = times[i];
    const startTime = new Date(time.startTime).getTime();

    if (startTime >= now) {
      nextTimes = time;
      break;
    }
  }

  return new Date(nextTimes.startTime).getTime();
};

const getRedirectedPageNum = (pagination, deleteAll) => {
  if (deleteAll) {
    return 0;
  }
  return (pagination.pageSize === 1 && pagination.page > 0) ? Math.max(0, pagination.page - 1) : pagination.page;
};

const getFetchWebinarsParams = (details, deleteAll, filterText) => {
  const redirectedPage = getRedirectedPageNum(details.pagination, deleteAll);
  const fromTime = moment().subtract(10, 'y').utc().format();
  const toTime = details.dateRange.end;
  const params = {
    experienceType: 'all',
    includes: 'activesessionsinfo',
    sortField: 'starttime',
    sortOrder: 'desc',
    id: 'past',
    page: redirectedPage,
    limit: details.pagination.limit,
    fromTime,
    toTime,
    filter: filterText
  };
  return params;
};

const formatWebinarsById = (webinars = []) => {
  const entities = {};

  webinars.forEach((webinar) => {
    entities[webinar.webinarKey] = {
      nextStartTime: getNextStartTime(webinar),
      ...webinar
    };
  });

  return entities;
};

const getPrevious = ({ page }) => (
  page - 1 > -1 ? page - 1 : undefined
);

const getNext = ({ page, limit, total }) => (
  (page + 1) * limit >= total ? undefined : (page + 1)
);

const getPageCount = ({ limit, total }) => (
  Math.ceil(total / limit)
);

const createPagination = ({
  total,
  limit,
  page,
  pageSize
}) => ({
  previous: getPrevious({ page }),
  next: getNext({ page, limit, total }),
  pageCount: getPageCount({ limit, total }),
  total,
  limit,
  page,
  pageSize
});

export {
  createPagination,
  formatWebinarsById,
  getFetchWebinarsParams
};
