import { createSelector } from 'reselect';

import { getLocale } from '../User/selectors';
import { twentyFourHourTimeFormat, webinarTimeDateFormat } from '../../Utils/formats';

export const selectTimeFormat = createSelector(
  [getLocale],
  (locale = '') => {
    const shouldUse24HourTimeFormat = ['es', 'fr', 'de', 'pt', 'it'].some((prefix) => (
      locale.slice(0, 2).toLowerCase() === prefix.slice(0, 2).toLowerCase()
    ));

    let timeFormat = webinarTimeDateFormat;

    if (shouldUse24HourTimeFormat) {
      timeFormat = twentyFourHourTimeFormat;
    }

    return timeFormat;
  }
);
